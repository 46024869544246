export const ROLES = {
  HAISTACK_ADMIN: 101,
  FIRM_ADMIN: 102,
  RECRUITER: 103,
  TEST_DRIVER: 104
};

export const PERMISSIONS = {
  CAN_MANAGE_USERS: 1000,
  CAN_MANAGE_EMPLOYEES: 1001,
  CAN_ACCESS_REPORTS: 1002,
  CAN_UPDATE_PRODUCTS: 1003,
  CAN_MODIFY_USERS: 1004,
  CAN_LOG_REQUEST: 1005,
  CAN_ADD_ADMINS: 1006,
  CAN_ADD_CUSTOMER: 1007,
  CAN_ADD_EMPLOYEE: 1008,
  CAN_VIEW_IMPERSONATE: 1009,
  SHOULD_BLUR_TEXT: 1010,
  CAN_DOWNLOAD_REPORTS: 1011,
  SHOULD_SHOW_ID: 1012,
  CAN_MANAGE_RELEASE_NOTES: 1013
};

export const getPermissions = {
  [ROLES.HAISTACK_ADMIN]: [
    PERMISSIONS.CAN_VIEW_IMPERSONATE,
    PERMISSIONS.CAN_MANAGE_USERS,
    PERMISSIONS.CAN_DOWNLOAD_REPORTS,
    PERMISSIONS.SHOULD_SHOW_ID,
    PERMISSIONS.CAN_MANAGE_RELEASE_NOTES
  ],
  [ROLES.FIRM_ADMIN]: [
    PERMISSIONS.CAN_MANAGE_USERS,
    PERMISSIONS.CAN_DOWNLOAD_REPORTS
  ],
  [ROLES.RECRUITER]: [
    PERMISSIONS.CAN_LOG_REQUEST,
    PERMISSIONS.CAN_DOWNLOAD_REPORTS
  ],
  [ROLES.TEST_DRIVER]: [PERMISSIONS.SHOULD_BLUR_TEXT]
};
