import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import _ from "lodash";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LatestReleaseModal from "views/ReleaseNotesManagment/release-note-modal";
import logoH from "./assets/logos/logoH.png";
import logoHaistack from "./assets/logos/logoHaistack.png";
import { AuthenticationGuard } from "./authentication-guard";
import {
  Notification,
  PageLoader,
  SuspenseLoader,
  TopNav
} from "./common/components";
import { APP_ROUTES } from "./common/constants/ROUTES";
import "./common/scss/global.scss";
import { sec } from "./security";
import { useImpersonateFirmMutation } from "./services/impersonate/impersonateApi";
import { setCurrentFirm } from "./services/impersonate/impersonateSlice";
import { setupLogin } from "./services/login/loginService";
import { setLoginPermissions } from "./services/permissions/permissionsSlice";
import { getUserData } from "./services/user/userSlice";
import { useAppDispatch, useAppSelector } from "./stateManagement/hooks";

const NotFound = lazy(() => import("views/404"));
const AccessDenied = lazy(() => import("views/AccessDenied"));
const Attorneys = lazy(() => import("views/Attorneys"));
const CandidatesManagement = lazy(() => import("views/CandidatesManagement"));
const Employees = lazy(() => import("views/Employees"));
const Firm = lazy(() => import("views/Firm"));
const Jobs = lazy(() => import("views/Jobs"));
const AddNewJob = lazy(() => import("views/Jobs/components/AddNewJob"));
const EditJob = lazy(() => import("views/Jobs/components/EditJob"));
const Job = lazy(() => import("views/Jobs/Job"));
const JobPublic = lazy(() => import("views/Jobs/JobPublic"));
const NoUser = lazy(() => import("views/NoUser"));
const UserManagement = lazy(() => import("views/UserManagement"));
const UserSettings = lazy(() => import("views/UserSettings"));
const ReleaseNotesCreate = lazy(
  () => import("views/ReleaseNotesManagment/ReleaseNotesCreate")
);
const ReleaseNotesManagment = lazy(() => import("views/ReleaseNotesManagment"));
const ReleaseNotesEdit = lazy(
  () => import("views/ReleaseNotesManagment/ReleaseNotesEdit")
);
export function App() {
  const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isPublic = location.pathname.includes("public");
  const [showReleaseNote, setShowReleaseNote] = useState(false);
  const [impersonateFirmQuery] = useImpersonateFirmMutation();
  const currentFirm = JSON.parse(
    localStorage.getItem("impersonateFirm") ?? "null"
  );
  const { isErrorMe, errorCode, isLoadingMe } = useAppSelector(
    (state) => state.user
  );

  // set getAccessTokenSilently in global module to reuse it outside a React component
  sec.setAccessTokenSilently(getAccessTokenSilently);

  useEffect(() => {
    if (isAuthenticated)
      getAccessTokenSilently().then((token) => {
        dispatch(getUserData({ accessToken: token })).then((data) => {
          const { user: newUser } = data.payload as { user: any };
          const role = _.get(newUser, "role", "null");
          const { permissions } = setupLogin(role);
          const email = _.get(newUser, "email", "null");
          const userId = _.get(newUser, "id", "null");
          const firmId = _.get(newUser, "employer.id", "null");
          const firmName = _.get(newUser, "employer.firm", "null");
          const employer = _.get(newUser, "employer", {});
          const expirationDate = _.get(newUser, "trial_expires_at", "");
          const showLatestReleaseNote = _.get(
            newUser,
            "show_latest_release_notes",
            ""
          );
          setShowReleaseNote(showLatestReleaseNote);
          dispatch(
            setLoginPermissions({
              permissions,
              email,
              userId,
              firmId,
              firmName,
              role,
              expirationDate
            })
          );
          if (role !== "haistack_admin") {
            dispatch(setCurrentFirm(employer));
          }
        });
      });
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (currentFirm !== null) {
      impersonateFirmQuery({
        id: currentFirm.id,
        firm: currentFirm
      });
    }
  }, []);

  /*   if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
  } */

  if (isErrorMe) {
    switch (errorCode) {
      case 1004:
        // User is not found
        return (
          <Suspense fallback={<SuspenseLoader />}>
            <NoUser />
          </Suspense>
        );
      case 1002:
        // Trial has ended
        return (
          <Suspense fallback={<SuspenseLoader />}>
            <AccessDenied />
          </Suspense>
        );
      default:
        return (
          <Suspense fallback={<SuspenseLoader />}>
            <NoUser />
          </Suspense>
        );
    }
  }

  if (isLoadingMe) {
    return (
      <div className="absolute top-0 flex justify-center h-full w-full bg-default-background">
        <PageLoader />
      </div>
    );
  }
  /*
   * className select-none is used to disable text selection on the page
   */

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Box className="bg-default-background min-h-screen">
        {/* Top Navigation */}
        {isAuthenticated && (
          <>
            <TopNav
              isLogged={isAuthenticated && !isPublic}
              tabs={[
                {
                  id: "nav-firm",
                  label: "Firm",
                  name: "firm",
                  path: APP_ROUTES.FIRM
                },
                {
                  id: "nav-jobs",
                  label: "Jobs",
                  name: "jobs",
                  path: APP_ROUTES.JOBS
                },
                {
                  id: "nav-attorneys",
                  label: "Search Candidates",
                  name: "attorneys",
                  path: APP_ROUTES.ATTORNEYS
                },
                {
                  id: "nav-retention",
                  label: "Retention",
                  name: "retention",
                  path: APP_ROUTES.RETENTION
                }
              ]}
              logo={logoH}
              offlineLogo={logoHaistack}
              avatar={user?.picture ?? ""}
              withSearch={false}
              onLogoutClick={() => {
                localStorage.clear();
                logout({
                  returnTo: `${window.location.origin}`
                });
              }}
            />
            <LatestReleaseModal
              open={showReleaseNote}
              onClose={() => setShowReleaseNote(false)}
            />
          </>
        )}
        <Notification />
        {/* Routes */}
        <Routes>
          {/* Public Routes */}
          <Route path="*" element={<NotFound />} />
          <Route path={APP_ROUTES.NOT_FOUND} element={<NotFound />} />
          <Route path={APP_ROUTES.ACCESS_DENIED} element={<AccessDenied />} />
          <Route path={APP_ROUTES.NO_USER} element={<NoUser />} />
          <Route path={APP_ROUTES.JOB_PUBLIC} element={<JobPublic />} />
          {/* Authenticated Routes */}
          <Route path="/" element={<AuthenticationGuard component={Firm} />} />
          <Route
            path={APP_ROUTES.USER_SETTINGS}
            element={<AuthenticationGuard component={UserSettings} />}
          />
          <Route
            path={APP_ROUTES.USER_MANAGEMENT}
            element={<AuthenticationGuard component={UserManagement} />}
          />
          <Route
            path={APP_ROUTES.CANDIDATES_MANAGEMENT}
            element={<AuthenticationGuard component={CandidatesManagement} />}
          />
          <Route
            path={APP_ROUTES.FIRM}
            element={<AuthenticationGuard component={Firm} />}
          />
          <Route
            path={APP_ROUTES.ATTORNEYS}
            element={<AuthenticationGuard component={Attorneys} />}
          />
          <Route
            path={APP_ROUTES.JOBS}
            element={<AuthenticationGuard component={Jobs} />}
          />
          <Route
            path={APP_ROUTES.JOB}
            element={<AuthenticationGuard component={Job} />}
          />
          <Route
            path={APP_ROUTES.JOB_PUBLIC_DEMO}
            element={<AuthenticationGuard component={Job} />}
          />
          <Route
            path={APP_ROUTES.ADD_NEW_JOB}
            element={<AuthenticationGuard component={AddNewJob} />}
          />
          <Route
            path={APP_ROUTES.EDIT_JOB}
            element={<AuthenticationGuard component={EditJob} />}
          />
          <Route
            path={APP_ROUTES.RETENTION}
            element={<AuthenticationGuard component={Employees} />}
          />
          <Route
            path={APP_ROUTES.RELEASE_NOTES_CREATE}
            element={<AuthenticationGuard component={ReleaseNotesCreate} />}
          />
          <Route
            path={APP_ROUTES.RELEASE_NOTES}
            element={<AuthenticationGuard component={ReleaseNotesManagment} />}
          />
          <Route
            path={APP_ROUTES.RELEASE_NOTES_EDIT}
            element={<AuthenticationGuard component={ReleaseNotesEdit} />}
          />
        </Routes>
      </Box>
    </Suspense>
  );
}

export default App;
