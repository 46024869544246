import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URLSearchParamsConstructor } from "../../helpers/UrlSearchParamConstructor";
import { sec } from "../../security";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiDealsSlice = createApi({
  reducerPath: "deals",
  tagTypes: ["getDealsByEmployerId", "getDealByAttorneyId"],
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  endpoints: (builder) => ({
    getDealsByEmployerId: builder.query({
      query: (employerId) =>
        `/api/v1/deals/employers_list?${
          employerId ? `employerId=${employerId}` : ""
        }`,
      providesTags: ["getDealsByEmployerId"]
    }),
    getDealByAttorneyId: builder.query({
      query: (args) => {
        const params = URLSearchParamsConstructor(args);
        return {
          url: "/api/v1/deals/attorney_list",
          method: "get",
          params
        };
      },
      providesTags: ["getDealByAttorneyId"]
    })
  })
});

export const { useGetDealByAttorneyIdQuery, useGetDealsByEmployerIdQuery } =
  apiDealsSlice;
