import Button from "common/components/Button";
import Modal from "common/components/Modal";
import PageLoader from "common/components/PageLoader";
import moment from "moment";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  useAgreeReleaseNoteNotificationMutation,
  useGetLatestNotesQuery
} from "services/release-notes/releaseNotesApi";

interface Props {
  open: boolean;
  onClose: () => void;
}

function LatestReleaseModal({ open, onClose }: Props) {
  const { data: latestNotes, isLoading } = useGetLatestNotesQuery(null);
  const [agreeReleaseNoteNotification, { isLoading: postLoading }] =
    useAgreeReleaseNoteNotificationMutation();

  const handleAcceptNotification = () => {
    agreeReleaseNoteNotification(null);
    onClose();
  };

  const getDate = (date: string) => {
    const formattedDate = date ? moment(date).format("MMMM D, YYYY") : null;
    return formattedDate;
  };
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < latestNotes.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };
  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <Modal
      title="Release Notes"
      open={open}
      onClose={onClose}
      closeIcon={false}
    >
      {!isLoading ? (
        latestNotes?.length > 0 && (
          <div className="p-4 w-full grid grid-cols-[50%_50%] gap-6">
            {(() => {
              const {
                notes,
                created_by: createdBy,
                version,
                published_at: publishedAt,
                updated_at: lastUpdate
              } = latestNotes[currentIndex] || {};

              return (
                <>
                  <div className="border border-solid rounded-md p-2 bg-gray-50 w-full">
                    <div className="flex items-center gap-2 flex-wrap overflow-auto max-h-[150px]">
                      <ReactMarkdown className="text-sm">{notes}</ReactMarkdown>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <span className="font-semibold text-sm">Version:</span>
                      <ReactMarkdown className="text-sm">
                        {version}
                      </ReactMarkdown>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-semibold text-sm">Created by:</span>
                      <ReactMarkdown className="text-sm">
                        {createdBy}
                      </ReactMarkdown>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-semibold text-sm">
                        Published at:
                      </span>
                      <ReactMarkdown className="text-sm">
                        {getDate(publishedAt)}
                      </ReactMarkdown>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-semibold text-sm">
                        Last update:
                      </span>
                      <ReactMarkdown className="text-sm">
                        {getDate(lastUpdate)}
                      </ReactMarkdown>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-4 w-full col-span-2 px-6">
                    {currentIndex > 0 ? (
                      <Button
                        id="back-note"
                        message="Back"
                        variant="secondary"
                        onClick={handleBack}
                      />
                    ) : (
                      <div />
                    )}
                    <span className="text-sm font-semibold">
                      {currentIndex + 1} / {latestNotes.length}
                    </span>
                    {currentIndex < latestNotes.length - 1 && (
                      <Button
                        id="next-note"
                        message="Next"
                        onClick={handleNext}
                      />
                    )}
                    {currentIndex === latestNotes.length - 1 && (
                      <Button
                        id="accept-all-notes"
                        message={
                          latestNotes.length > 1 ? "Accept all" : "Accept"
                        }
                        onClick={handleAcceptNotification}
                        isLoading={postLoading}
                      />
                    )}
                  </div>
                </>
              );
            })()}
          </div>
        )
      ) : (
        <PageLoader />
      )}
    </Modal>
  );
}
export default LatestReleaseModal;
