import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../security";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiReleaseNotesSlice = createApi({
  reducerPath: "releaseNotes",
  tagTypes: ["getNotes", "getNote", "getLatestNotes"],
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  endpoints: (builder) => ({
    getAllNotes: builder.query({
      query: () => `/api/v1/release_notes`,
      providesTags: ["getNotes"]
    }),
    getLatestNotes: builder.query({
      query: () => `/api/v1/release_notes/latest`,
      providesTags: ["getLatestNotes"]
    }),
    getNote: builder.query({
      query: (id) => `/api/v1/release_notes/${id}`,
      providesTags: ["getNote"]
    }),
    createNote: builder.mutation({
      query: (body) => ({
        url: `/api/v1/release_notes`,
        method: "POST",
        body
      }),
      invalidatesTags: ["getNotes"]
    }),
    agreeReleaseNoteNotification: builder.mutation({
      query: () => ({
        url: `/api/v1/release_notes/agree`,
        method: "POST"
      }),
      invalidatesTags: ["getLatestNotes"]
    }),

    editNote: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/v1/release_notes/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["getNotes", "getNote"]
    }),
    deleteNote: builder.mutation({
      query: (id) => ({
        url: `/api/v1/release_notes/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["getNotes", "getNote"]
    })
  })
});

// Export hooks for usage in functional components
export const {
  useGetAllNotesQuery,
  useCreateNoteMutation,
  useEditNoteMutation,
  useGetNoteQuery,
  useDeleteNoteMutation,
  useGetLatestNotesQuery,
  useAgreeReleaseNoteNotificationMutation
} = apiReleaseNotesSlice;
